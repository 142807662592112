import * as React from 'react'
import Slider from '../components/SliderHome'
import {
  ctaContent,
} from '../components/styles/Cta.styles'

const Section3 = () => {
  return (
    <div css={ctaContent} style={{ paddingBottom: 48 }}>
      <Slider />
    </div>
  )
}

export default Section3