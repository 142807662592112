import React, { Fragment } from 'react'
import IcoWhats from '../images/new/icons/whatsapp.svg'

const ShareSocialSite = props => (
  <Fragment>
    <div id="gfg-side-sticky-tab">
      <div class="gfg-textwidget">
        <div class="sticky-container">
          <div class="slideout">
            <div class="inner">
              <div class="inner-item"> 
                <a
                  href='https://api.whatsapp.com/send?phone=5561996358261'
                >
                  <img style={{ width: 56 }} src={IcoWhats} alt='' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default ShareSocialSite
