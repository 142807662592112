import React, { Fragment } from 'react'
import Slider from '../components/CTA'
import { Link } from 'gatsby'
import Button from './ConhecaNossaEstruturaBtn'
import Button2 from './AtividadesProjetosBtn'
import Button3 from '../components/StartNowBtn'
import AtividadesProjetos from '../images/new/atividades-projetos.svg' 
import ArrowM from '../images/new/arrowBoxM.svg'
import Kid1 from '../images/new/kingdom-kids.png'
import Kid2 from '../images/new/kingdom-school.png'

import {
  section,
  content,
  column,
  columns,
  container,
  boxImage2,
  boxContent,
  boxImageMobile,
  box1,
  box2,
  projectSlider,
  ctaContent,
  boxContent2,
  desktop,
  desktopM,
  mobile,
  boxContentMobile,
  ctaEstudeConosco,
} from './styles/Section2.styles'

const Section2 = () => {
  return (
    <Fragment>
      <div css={section}>
        <div css={[container, content]}>
          <h1>Uma década estimulando nossos alunos a enfrentarem os desafios da vida</h1>
          <p>Em 10 anos de história, a nossa escola cresceu e tornou-se uma referência no âmbito educacional, resultado do nosso comprometimento com a cidadania mundial na formação das nossas crianças.</p>
          <div css={[columns, desktop]}>
            <div css={column}>
              <Link to='/kingdom-kids'>
                <div css={[boxImage2, box1]} style={{ marginLeft: 'auto', border: '1px solid #FFC208' }}>
                  <h2>Kingdom Kids</h2>
                  <div css={boxContent}>
                    <h3>Conheça nossa unidade para Educação Infantil.</h3>
                    <img src={ArrowM} />
                  </div>
                </div>
              </Link>
            </div>
            <div css={column}>
              <Link to='/kingdom-school'>
                <div css={[boxImage2, box2]} style={{ border: '1px solid #41E4AB' }}>
                  <h2>Kingdom School</h2>
                  <div css={boxContent} style={{ backgroundColor: '#41E4AB' }}>
                    <h3>Explore nossa Unidade para Ensino Fundamental.</h3>
                    <img src={ArrowM} />
                  </div>
                </div>
              </Link>
            </div>
          </div>
          {/* Mobile */}
          <div css={[columns, mobile]} style={{ marginTop: 42 }}>
            <div css={column}>
              <Link to='/kingdom-kids'>
                <div css={boxImageMobile}>
                  <h2>Kingdom Kids</h2>
                  <img src={Kid1} style={{ width: '100%' }} />
                  <div css={boxContentMobile}>
                    <h3>Conheça nossa unidade para Educação Infantil.</h3>
                    <img src={ArrowM} />
                  </div>
                </div>
              </Link>
            </div>
            <div css={column}>
              <Link to='/kingdom-school'>
                <div css={boxImageMobile}>
                  <h2>Kingdom School</h2>
                  <img src={Kid2} style={{ width: '100%' }} />
                  <div css={boxContentMobile}>
                    <h3>Explore nossa Unidade para Ensino Fundamental.</h3>
                    <img src={ArrowM} />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div css={container} style={{ marginTop: 88 }}>
          <div css={projectSlider}>
            <h2>Um projeto <span>inteligente, bonito, funcional </span> e <span>alegre</span></h2>
            <p>As salas são dinâmicas, claras, arejadas e os espaços se conectam com muita facilidade.</p>
          </div>
        </div>
        <div css={container}>
          <Slider />      
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Link to='/estrutura'><Button>CONHEÇA NOSSA ESTRUTURA</Button></Link>
          </div>
        </div>
        <div css={[ctaContent, desktopM]}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '50%' }}>
              <img src={AtividadesProjetos} alt=''  />
            </div>
            <div style={{ width: '50%', display: 'flex', alignItems: 'center' }}>
              <div css={boxContent2}>
                <h2>Atividades e Projetos</h2>
                <p>Nossos alunos podem se dedicar a aulas extras voltadas a artes e esportes, distribuídas entre todas as faixas etárias.</p>
                <Link to='/atividades-e-projetos'><Button2>CONHEÇA NOSSAS ATIVIDADES E PROJETO</Button2></Link>
              </div>
            </div>
          </div>
        </div>
        <div css={[ctaContent, mobile]}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div css={boxContent2}>
              <h2>Atividades e Projetos</h2>
              <p>Nossos alunos podem se dedicar a aulas extras voltadas a artes e esportes, distribuídas entre todas as faixas etárias.</p>
              <Link to='/atividades-e-projetos'><Button2>CONHEÇA NOSSAS ATIVIDADES E PROJETO</Button2></Link>
            </div>
            <div style={{ marginTop: 110 }}>
              <img src={AtividadesProjetos} alt='' style={{ width: '100%' }}  />
            </div>
          </div>
        </div>
      </div>
      <div css={ctaEstudeConosco}>
        <div css={container}>
          <h2>Estude Conosco</h2>
          <p>Você está a um formulário de distância do nosso reino de aprendizado e conhecimento.</p>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Link to='/estude-conosco'><Button3>COMECE AGORA</Button3></Link>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Section2