import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img1 from '../images/new/slider/1.png'
import Img2 from '../images/new/slider/2.png'
import Img3 from '../images/new/slider/3.png'

import {
  sliderHomeImg
} from './styles/Section2.styles'

class VideosCarousel extends React.Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      autoplaySpeed: 6500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            dots: false
          }
        }
      ]
    }
    
    return (
      <Fragment>
        <Slider {...settings}>
          <div>
            <div css={sliderHomeImg}>
              <img src={Img1} alt='' />
            </div>
          </div>
          <div>
            <div css={sliderHomeImg}>
              <img src={Img2} alt='' />
            </div>
          </div>
          <div>
            <div css={sliderHomeImg}>
              <img src={Img3} alt='' />
            </div>
          </div>
          <div>
            <div css={sliderHomeImg}>
              <img src={Img1} alt='' />
            </div>
          </div>
          <div>
            <div css={sliderHomeImg}>
              <img src={Img2} alt='' />
            </div>
          </div>
          <div>
            <div css={sliderHomeImg}>
              <img src={Img3} alt='' />
            </div>
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default VideosCarousel