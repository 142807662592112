import * as React from 'react'

import {
  btnConhecaNossaEstrutura
} from './styles/Buttons.styles'

const ConhecaNossaEstruturaBtn = ({ children }) => (
  <button css={btnConhecaNossaEstrutura}>{ children }</button>
)

export default ConhecaNossaEstruturaBtn
