import * as React from 'react'
import { globalStyles } from '../components/layout.styles'
import { Global } from '@emotion/react'
import * as Scroll from 'react-scroll'
import NavBar from '../components/NavBar'
import Header from '../components/Header'
import Section2 from '../components/Section2'
import AgendeSuaVisita from '../components/AgendeUmaVisita'
import Footer from '../components/Footer'
import SEO from "../components/Seo"
import Share from '../components/Share'
import Novidades from '../components/NovidadesSlider'

const IndexPage = () => {
  return (
    <div>
      <Global styles={globalStyles} />
      <SEO title="KINGDOM SCHOOL | Um reino de aprendizado e conhecimento" />
      <NavBar />
      <Scroll.Element>
        <Header />
      </Scroll.Element>
      <Scroll.Element>
        <Section2 />
      </Scroll.Element>
      {false && <Novidades />}
      <AgendeSuaVisita />
      <Footer />
      <Share />
    </div>
  )
}

export default IndexPage
