import { css } from "@emotion/react"

export const ctaContent = css`
  background-color: #5416FF;
  font-family: 'Lato', sans-serif;
  /* @media (max-width: 480px) {
    padding-top: 52px;
    padding-bottom: 0px;
    border-bottom: 2px solid #FFFAA9;
  } */
  h2 {
    font-weight: 900;
    font-size: 38px;
    line-height: 40px;
    color: #9DD2DA;
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 36px;
    max-width: 383.27px;
    width: 100%;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #9DD2DA;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    max-width: 457.32px;
    margin-bottom: 36px;
  }
  a {
    width: 90%;
  }
`

export const boxContent2 = css`
  
  margin-left: auto;
  padding-right: 120px;
  h2 {
    font-weight: 900;
    font-size: 38px;
    line-height: 40px;
    color: #9DD2DA;
    font-family: 'Source Sans Pro', sans-serif;
    margin-bottom: 36px;
    max-width: 383.27px;
    width: 100%;
  }
  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #9DD2DA;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    max-width: 457.32px;
    margin-bottom: 36px;
  }

`

export const ctaPrqEstudar = css`
  background-color: #FFFAA9;
  font-family: 'Lato', sans-serif;
  padding-top: 62px;
  padding-bottom: 54px;
  @media (max-width: 480px) {
    padding-top: 60px;
    padding-bottom: 34px;
  }
`

export const ctaSejaUmCorretor = css`
  background-color: #EAEAEA;
  font-family: 'Lato', sans-serif;
  padding-top: 0px;
  padding-bottom: 54px;
  @media (max-width: 480px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`

export const container = css`
  width: 100%;
  margin: 0 auto;
  max-width: 1170px;
`

export const columns = css`
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	/* margin: 5px 0; */
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
    flex-direction: column;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const column = css`
  flex: 1;
	margin: 2px;
	padding: 10px;
	&:first-child { margin-left: 0; }
	&:last-child { margin-right: 0; }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }
  }
`

export const ctaBox = css`
  width: 100%;
  max-width: 970px;
  background: #3E6CFF;
  border-radius: 79px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 60px;
  h2 {
    font-size: 55px;
    line-height: 69px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    font-weight: normal;
    margin: 0;
    color: #EAEAEA;
    margin-bottom: 18px;
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #EAEAEA;
    max-width: 769px;
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 480px) {
    border-radius: 0px;
    h2 {
      font-size: 45px;
      line-height: 54px;
      text-align: center;
      margin-bottom: 28px;
      max-width: 328px;
      width: 100%;
    }
    p {
      max-width: 328px;
    }
  }
`

export const ctaBoxCorretor = css`
  width: 100%;
  max-width: 970px;
  background: #1E1E1E;
  border-radius: 79px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 60px;
  h2 {
    font-size: 72px;
    line-height: 89px;
    display: flex;
    align-items: center;
    letter-spacing: -0.05em;
    font-weight: normal;
    margin: 0;
    color: #EAEAEA;
    margin-bottom: 18px;
  }
  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #EAEAEA;
    max-width: 769px;
    width: 100%;
    margin: 0;
    margin-bottom: 32px;
    text-align: center;
    span {
      font-weight: bold;
    }
  }
  @media (max-width: 480px) {
    border-radius: 0px;
    h2 {
      font-size: 45px;
      line-height: 54px;
      text-align: center;
      margin-bottom: 28px;
      max-width: 328px;
      width: 100%;
    }
    p {
      max-width: 328px;
    }
  }
`

export const desktop = css`
  display: flex;
  @media (max-width: 480px) {
    display: none;
  }
`

export const mobile = css`
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  h2 {
    text-align: center;
    margin-top: 102px;
  }
  p {
    text-align: center;
    max-width: 328px;
    width: 100%;
  }
  @media (min-width: 480px) {
    display: none;
  }
`