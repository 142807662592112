import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Item1 from '../images/new/blog/1.png'

import {
  itemBlog,
  tags,
  tagItem
} from '../components/styles/Novidades.styles'
import { Link } from "gatsby"

class VideosCarousel extends React.Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      autoplaySpeed: 6500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            dots: false
          }
        }
      ]
    }
    
    return (
      <Fragment>
        <Slider {...settings}>
          <div>
            <Link to='/blog-item'>
              <div css={itemBlog}>
                <h4>21 Nov 2021</h4>
                <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                <div css={tags}>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                </div>
                <img src={Item1} alt='/' />
              </div>
            </Link>
          </div>
          <div>
            <Link to='/blog-item'>
              <div css={itemBlog}>
                <h4>21 Nov 2021</h4>
                <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                <div css={tags}>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                </div>
                <img src={Item1} alt='/' />
              </div>
            </Link>
          </div>
          <div>
            <Link to='/blog-item'>
              <div css={itemBlog}>
                <h4>21 Nov 2021</h4>
                <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                <div css={tags}>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                </div>
                <img src={Item1} alt='/' />
              </div>
            </Link>
          </div>
          <div>
            <Link to='/blog-item'>
              <div css={itemBlog}>
                <h4>21 Nov 2021</h4>
                <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.</p>
                <div css={tags}>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                </div>
                <img src={Item1} alt='/' />
              </div>
            </Link>
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default VideosCarousel