import * as React from 'react'
import Button from '../components/StartNowBtn'
import Img1 from '../images/new/header/header-2.png' 
import Bird from '../images/new/header/bird.svg' 

import {
  column,
  columns,
  container,
  header,
  content,
  mobile,
  desktop,
  cloud,
  cloudItem
} from '../components/styles/Header.styles'
import { Link } from 'gatsby'

const Header = () => {
  return (
    <div css={[header, content]}>
      <div css={container}>
        {false && <div css={[cloud, cloudItem]}/>}
        <div css={columns}>
          <div css={column} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <img css={mobile} src={Img1} alt='' style={{ marginBottom: 32 }} />
            <h1>Um reino de aprendizado e conhecimento</h1>
            <p>O amor e acolhimento são as marcas da nossa escola. Conheça nossa história!</p>
            <img css={desktop} src={Bird} alt='' style={{ width: 32 }} />
            <Link to='/nossa-historia' css={desktop}><Button>EXPLORE A KINGDOM</Button></Link>
          </div>
          <div css={column}>
            <img css={desktop} src={Img1} alt='' />
            <img css={mobile} src={Bird} alt='' style={{ width: 32, marginLeft: 16 }} />
            <Link to='/nossa-historia' css={mobile} style={{ margin: 18, marginTop: 0 }}><Button>EXPLORE A KINGDOM</Button></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header