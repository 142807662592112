import * as React from 'react'
import Img from '../images/new/agende-uma-visita.png'

import {
  column,
  columns,
  container,
  AgendeUmaVisitaStyle,
  content,
  contentPosition,
} from './styles/AgendeUmaVisita.styles'

import { 
  formulario, 
  label,
  btnEnviar,
  radioBtns
} from './styles/Teste.styles'

const AgendeUmaVisita = () => {
  return (
    <div css={[AgendeUmaVisitaStyle, content]}>
      <div css={container}>
        <div css={columns}>
          <div css={column} style={{ display: 'flex' }}>
            <div css={contentPosition}>
              <h2>Agende uma visita!</h2>
              <h3>Será um prazer receber você na Kingdom.</h3>
              <p>Utilize o formulário ao lado para entrar em contato conosco e combinar o melhor dia e horário</p>
              <div css={formulario} style={{ margin: 0 }}>
                <form style={{ margin: '0 auto' }}>
                  <div css={radioBtns}>
                    <input type="radio" id="age1" name="age" value="30" checked /> Kids
                    <div style={{ marginRight: 24 }} />
                    <input type="radio" id="age1" name="age" value="30" /> School
                  </div>
                  <div css={label}>
                    <input placeholder='Nome' type="text" name="name" id="name" required />
                  </div>
                  <div css={label}>
                    <input placeholder='E-mail' type="text" name="email" id="email" required />
                  </div>
                  <div css={label}>
                    <input placeholder='Telefone' type="text" name="fone" id="fone" required />
                  </div>
                  <button type="submit" css={btnEnviar} style={{ marginTop: 56 }}>ENVIAR</button>
                </form> 
              </div>
            </div>
          </div>
          <div css={column}>
            <img src={Img} alt=''  />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgendeUmaVisita